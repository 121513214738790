<template>
  <section id="registration-confirm-form">
    <div class="popup-window-title">
      <h6 class="popup-window-title__head">
        {{ $t('На телефон') }}
        <label class="popup-window__sender-info">{{ $store.state.user.registerCanonicalPhone }}</label> <br />{{
          $t('отправлен пароль')
        }}
      </h6>
    </div>
    <UcForm ref="form" :handler="login">
      <uc-input
        v-model="code"
        v-focus
        rules="required"
        name="code"
        class="contacts-input"
        :label="$t('Код')"
        :title="$t('Введите код')"
        type="text"
      />
      <div class="form-refresh m-xxxxlg-bottom">
        <p v-if="isTimerActive" class="popup-window-oferta__text">
          {{ $t('Отправить пароль повторно') }} - 00:{{ time }}
        </p>

        <span v-if="!isTimerActive" class="form-refresh__btn" @click="resendPassword()">{{
          $t('Отправить пароль повторно')
        }}</span>
      </div>

      <uc-loader-button type="submit" class="uc-button-black" :loading="loading" :disabled="loading">{{
        $t('Войти')
      }}</uc-loader-button>
    </UcForm>
  </section>
</template>

<script>
import TimerMixin from '~/src/components/auth/mixin/Timer.mixin.js';
import AuthErrorsMixin from 'u-auth/lib/components/mixin/AuthErrors.mixin.js';
import focus from '~/src/directives/focus.js';

export default {
  directives: { focus },
  mixins: [TimerMixin, AuthErrorsMixin],
  data: () => {
    return {
      code: '',
      loading: false
    };
  },
  mounted() {
    this.$eventBus.$emit('auth_forms:timer:start', 60);
  },
  methods: {
    login() {
      this.loading = true;
      this.$eventBus.$emit('auth_forms:validation:reset');
      this.$store
        .dispatch('user/confirmByPhone', this.code)
        .then(() => {
          this.$eventBus.$emit('auth_forms:change_password_form:display');
        })
        .catch((error) => {
          this.handleErrors(error);
        })
        .finally(() => (this.loading = false));
    },
    resendPassword() {
      this.$store
        .dispatch('user/registerByPhone', this.$store.state.user.registrationData)
        .then(({ data }) => {
          this.$eventBus.$emit('auth_forms:timer:start', data.diff || 60);
        })
        .catch((error) => {
          this.handleErrors(error);
        });
    }
  }
};
</script>
