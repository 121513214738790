<template>
  <section id="registration-tab">
    <phone-registration-form
      v-if="isDisplayPhoneForm"
      :current-phone="currentPhone"
      :current-name="currentName"
      :is-chicco-time-registration="isChiccoTimeRegistration"
    />
    <registration-confirm-form v-if="isDisplayConfirmForm" />
    <registration-change-password-form v-if="isDisplayChangePasswordForm" />
    <success-login v-if="isDisplaySuccessLogin" />
    <OAuth-Phone-Form v-if="isDisplayOAuthPhoneForm" />
  </section>
</template>

<script>
import PhoneRegistrationForm from '~/src/components/auth/form/registration/PhoneForm.vue';
import RegistrationConfirmForm from '~/src/components/auth/form/registration/ConfirmForm.vue';
import RegistrationChangePasswordForm from '~/src/components/auth/form/ChangePasswordForm.vue';
import SuccessLogin from '~/src/components/auth/form/login/SuccessLogin.vue';
import OAuthPhoneForm from '~/src/components/auth/form/registration/OAuthPhoneForm.vue';

export default {
  components: {
    PhoneRegistrationForm,
    RegistrationConfirmForm,
    RegistrationChangePasswordForm,
    SuccessLogin,
    OAuthPhoneForm
  },
  props: {
    currentPhone: {
      type: String,
      default: ''
    },
    currentName: {
      type: String,
      default: ''
    },
    isChiccoTimeRegistration: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      isDisplayPhoneForm: true,
      isDisplayConfirmForm: false,
      isDisplayChangePasswordForm: false,
      isDisplaySuccessLogin: false,
      isDisplayOAuthPhoneForm: false
    };
  },
  created() {
    this.$eventBus.$on('auth_forms:phone_form:display', this.displayPhoneForm);
    this.$eventBus.$on('auth_forms:confirm_form:display', this.displayConfirmForm);
    this.$eventBus.$on('auth_forms:change_password_form:display', this.displayChangePasswordForm);
    this.$eventBus.$on('auth_forms:all_forms:hide', this.hideAllForms);
    this.$eventBus.$on('auth_forms:register_welcome:display', this.displayRegisterWelcome);
    this.$eventBus.$on('auth_forms:oauth_phone_form:display', this.displayOAuthPhoneForm);
  },
  beforeDestroy() {
    this.$eventBus.$off('auth_forms:phone_form:display', this.displayPhoneForm);
    this.$eventBus.$off('auth_forms:confirm_form:display', this.displayConfirmForm);
    this.$eventBus.$off('auth_forms:change_password_form:display', this.displayChangePasswordForm);
    this.$eventBus.$off('auth_forms:all_forms:hide', this.hideAllForms);
    this.$eventBus.$off('auth_forms:register_welcome:display', this.displayRegisterWelcome);
    this.$eventBus.$off('auth_forms:oauth_phone_form:display', this.displayOAuthPhoneForm);
  },
  methods: {
    displayPhoneForm() {
      this.hideAllForms();
      this.isDisplayPhoneForm = true;
    },
    displayConfirmForm() {
      this.hideAllForms();
      this.isDisplayConfirmForm = true;
    },
    displayChangePasswordForm() {
      this.hideAllForms();
      this.isDisplayChangePasswordForm = true;
    },
    displayRegisterWelcome() {
      this.hideAllForms();
      this.isDisplaySuccessLogin = true;
    },
    displayOAuthPhoneForm() {
      this.hideAllForms();
      this.isDisplayOAuthPhoneForm = true;
    },
    hideAllForms() {
      this.isDisplayPhoneForm = false;
      this.isDisplayConfirmForm = false;
      this.isDisplayChangePasswordForm = false;
      this.isDisplayOAuthPhoneForm = false;
    }
  }
};
</script>
