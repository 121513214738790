<template>
  <section id="registration-tab">
    <login-form v-if="isDisplayLoginForm" :current-phone="currentPhone" :is-checkout="isCheckout" />
    <reset-password v-if="isDisplayResetPasswordForm" />
    <change-password-form v-if="isDisplayChangePasswordForm" />
    <success-login v-if="isDisplaySuccessLogin" />
  </section>
</template>

<script>
import LoginForm from '~/src/components/auth/form/login/LoginForm.vue';
import ResetPassword from '~/src/components/auth/form/login/ResetPassword.vue';
import ChangePasswordForm from '~/src/components/auth/form/ChangePasswordForm.vue';
import SuccessLogin from '~/src/components/auth/form/login/SuccessLogin.vue';

export default {
  components: {
    LoginForm,
    ResetPassword,
    ChangePasswordForm,
    SuccessLogin
  },
  props: {
    currentPhone: {
      type: String,
      default: ''
    },
    isCheckout: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      isDisplayLoginForm: true,
      isDisplayResetPasswordForm: false,
      isDisplayChangePasswordForm: false,
      isDisplaySuccessLogin: false,
      isActiveBonusProgram: process.env.BONUS_PROGRAM
    };
  },
  created() {
    this.$eventBus.$on('auth_forms:login_tab:phone_form:display', this.displayLoginForm);
    this.$eventBus.$on('auth_forms:login_tab:reset_password_form:display', this.displayResetPasswordForm);
    this.$eventBus.$on('auth_forms:login_tab:change_password_form:display', this.displayChangePasswordForm);
    this.$eventBus.$on('auth_forms:login_tab:success_notice:display', this.displaySuccessLogin);
    this.$eventBus.$on('auth_forms:login_tab:all_forms:fide', this.hideAllForms);
  },
  beforeDestroy() {
    this.$eventBus.$off('auth_forms:login_tab:phone_form:display', this.displayLoginForm);
    this.$eventBus.$off('auth_forms:login_tab:reset_password_form:display', this.displayResetPasswordForm);
    this.$eventBus.$off('auth_forms:login_tab:change_password_form:display', this.displayChangePasswordForm);
    this.$eventBus.$off('auth_forms:login_tab:success_notice:display', this.displaySuccessLogin);
    this.$eventBus.$off('auth_forms:login_tab:all_forms:fide', this.hideAllForms);
  },
  methods: {
    displayLoginForm() {
      this.hideAllForms();
      this.isDisplayLoginForm = true;
    },
    displayResetPasswordForm() {
      this.hideAllForms();
      this.isDisplayResetPasswordForm = true;
    },
    displayChangePasswordForm() {
      this.hideAllForms();
      this.isDisplayChangePasswordForm = true;
    },
    displaySuccessLogin() {
      this.hideAllForms();
      this.isDisplaySuccessLogin = true;
      if (this.isActiveBonusProgram) {
        this.$eventBus.$emit('auth_forms:login_tab:success_notice:bonus', true);
      }
    },
    hideAllForms() {
      this.isDisplayLoginForm = false;
      this.isDisplayResetPasswordForm = false;
      this.isDisplayChangePasswordForm = false;
      this.isDisplaySuccessLogin = false;
    }
  }
};
</script>
