<template>
  <section id="login-form">
    <div class="popup-window-title">
      <h6 class="popup-window-title__head">
        <label>{{ $t('Войти с помощью номера телефона') }}</label>
      </h6>
    </div>
    <UcForm ref="form" :handler="login">
      <uc-phone-input ref="phoneField" v-model="canonicalPhone" :with-star="false" />
      <uc-input
        id="login-password"
        v-model="password"
        rules="required|min:6"
        name="password"
        type="password"
        :label="$t('Пароль')"
        mode="passive"
        :with-star="false"
      />
      <div class="popup-window-desc__soc">{{ $t('Или продолжить, используя социальные сети') }}:</div>
      <oauth-block />

      <div class="form-refresh">
        <p v-if="isTimerActive" class="popup-window-oferta__text">
          {{ $t('Отправить пароль повторно') }} - 00:{{ time }}
        </p>
        <span v-else class="form-refresh__btn" @click="resendPassword">
          {{ $t('Восстановление пароля') }}
        </span>
      </div>
      <uc-loader-button type="submit" class="uc-button-black" :loading="loading" :disabled="loading">{{
        $t('Войти')
      }}</uc-loader-button>
      <uc-button class="uc-button-white-black auth-cancel" @click="$eventBus.$emit('modals:register:close')">
        {{ $t('Отмена') }}
      </uc-button>
    </UcForm>
  </section>
</template>

<script>
import TimerMixin from '~/src/components/auth/mixin/Timer.mixin.js';
import AuthErrorsMixin from 'u-auth/lib/components/mixin/AuthErrors.mixin.js';
import focus from '~/src/directives/focus.js';
import OauthBlock from '~/src/components/auth/form/OauthBlock.vue';
import { userStore } from 'u-store';
const { mutationTypes: userMutation } = userStore;

export default {
  components: {
    OauthBlock
  },
  directives: { focus },
  mixins: [TimerMixin, AuthErrorsMixin],
  props: {
    currentPhone: {
      type: String,
      default: ''
    },
    isCheckout: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      canonicalPhone: '',
      password: '',
      loading: false
    };
  },
  mounted() {
    if (this.currentPhone.length) {
      this.canonicalPhone = this.currentPhone;
    }
  },
  methods: {
    login() {
      this.loading = true;
      this.$eventBus.$emit('auth_forms:validation:reset');
      this.$store
        .dispatch('user/login', { login: this.canonicalPhone, password: this.password })
        .then(() => {
          if (!this.isCheckout) {
            this.$eventBus.$emit('auth_forms:login_tab:success_notice:display');
          } else {
            this.$eventBus.$emit('modals:register:close');
          }
          this.$gtm.checkUser(this.$store.state.user, 'phone');
          this.$gtm.setUserInfo(this.$store.state.user);
          this.$eventBus.$emit('user:auth:login');
        })
        .catch((error) => {
          this.handleErrors(error);
        })
        .finally(() => (this.loading = false));
    },
    async resendPassword() {
      try {
        // We need to validate only phone field
        this.$refs.form.resetErrors();
        const { valid = true } = (await this.$refs.phoneField?.$refs?.provider?.validate()) || {};
        if (!valid) {
          return;
        }
        await this.$store.dispatch('user/forgotPassword', {
          phone: this.canonicalPhone
        });
        this.$eventBus.$emit('auth_forms:login_tab:reset_password_form:display');
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.$store.commit(`user/${userMutation.SET_REGISTER_CANONICAL_PHONE}`, this.canonicalPhone);
      }
    }
  }
};
</script>
