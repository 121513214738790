export default {
  data: () => {
    return {
      isTimerActive: false,
      time: 60,
      interval: null
    };
  },
  created() {
    this.$eventBus.$on('auth_forms:timer:start', this.startTimer);
  },
  beforeDestroy() {
    this.$eventBus.$off('auth_forms:timer:start', this.startTimer);
  },
  methods: {
    startTimer(seconds) {
      this.stopTimer();
      this.time = seconds || 60;

      this.isTimerActive = true;
      this.interval = setInterval(() => {
        this.time <= 0 ? this.stopTimer() : this.time--;
      }, 1000);
    },
    stopTimer() {
      this.isTimerActive = false;
      clearInterval(this.interval);
    }
  }
};
