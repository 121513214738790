<template>
  <section>
    <section v-if="isActiveBonusProgram && isBonusServiceEnable" class="success-bonus">
      <bonus-auth-greetings v-model="loading" />
      <div v-if="isBonusProgramMember && !loading" class="auth-bonus-status-button">
        <uc-button class="uc-button-black green-btn" @click="$eventBus.$emit('modals:register:close')">
          {{ $t('На сайт') }}
        </uc-button>
        <uc-button class="uc-button-black green-btn" @click="openProfilePage()">{{ $t('Личный кабинет') }}</uc-button>
      </div>
      <div v-if="!isBonusProgramMember && !loading" class="auth-bonus-status-button">
        <uc-button class="uc-button-black green-btn" @click="openBonusPage()">{{ $t('Подробнее') }}</uc-button>
        <uc-button class="uc-button-black green-btn" @click="becomeMember()">{{ $t('Стать участником') }}</uc-button>
        <div class="product-modal_close" @click="$eventBus.$emit('modals:register:close')" />
      </div>
    </section>
    <section v-else id="registration-confirm-form">
      <div class="popup-window-title">
        <h6 class="popup-window-title__head">
          {{ $t('Здравствуйте') }} {{ $store.state.user.data.firstName || '' }} <br /><br />
        </h6>
      </div>

      <div class="popup-window-text">{{ $t('Вы хотите перейти в личный кабинет или вернуться на сайт') }}?</div>

      <uc-button class="uc-button-black green-btn" @click="$eventBus.$emit('modals:register:close')">
        {{ $t('На сайт') }}
      </uc-button>

      <uc-button class="uc-button-black green-btn" @click="openProfilePage()">{{ $t('Личный кабинет') }}</uc-button>
    </section>
  </section>
</template>

<script>
import BonusAuthGreetings from '~/src/components/bonus/BonusAuthGreetings';
import BonusProgramMemberMixin from '~/src/mixins/BonusProgramMember';
import { RouterMixin } from 'u-mixins';

export default {
  components: { BonusAuthGreetings },
  mixins: [BonusProgramMemberMixin, RouterMixin],
  data: () => {
    return {
      hasBonus: true,
      loading: false
    };
  },
  watch: {
    isBonusServiceEnable: {
      handler(value) {
        this.$eventBus.$emit('auth_forms:login_tab:success_notice:bonus', value);
      },
      immediate: true
    }
  },
  mounted() {
    this.$eventBus.$emit('auth_modal:tabs::hide');
  },
  beforeDestroy() {
    this.$eventBus.$emit('auth_forms:login_tab:success_notice:bonus', false);
  },
  methods: {
    openProfilePage() {
      this.$eventBus.$emit('modals:register:close');
      this.moveToByName('profile-page');
    },
    openBonusPage() {
      this.$eventBus.$emit('modals:register:close');
      const isOnInfoPage = this.$route.name.startsWith('info-page');
      if (isOnInfoPage) {
        this.$router.replace({ params: { slug: 'bonus-rules' } });
      } else {
        this.moveToByName('info-page', { slug: 'bonus-rules' });
      }
    },
    becomeMember() {
      this.moveToByName('profile-page', null, '#bonusprogram');
      this.$eventBus.$emit('modals:register:close');
    }
  }
};
</script>
