<template>
  <uc-modal v-model="dialogDisplay" :close-button="false">
    <section id="auth-modal" class="auth-modal" :class="{ 'auth-modal-bonuses': modalHasBonus }">
      <uc-tabs :visible="!isTabsHidden" :tabs="tabs" :selected="activeTab" @selected="setSelected">
        <uc-tab :is-selected="activeTab === 'login'"
          ><login-tab :current-phone="currentPhone" :is-checkout="isCheckout"
        /></uc-tab>
        <uc-tab :is-selected="activeTab === 'registration'"
          ><registration-tab
            :current-phone="currentPhone"
            :current-name="currentName"
            :is-chicco-time-registration="isChiccoTimeRegistration"
        /></uc-tab>
      </uc-tabs>
    </section>
  </uc-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import LoginTab from '~/src/components/auth/tab/LoginTab.vue';
import RegistrationTab from '~/src/components/auth/tab/RegistrationTab.vue';

export default {
  components: {
    LoginTab,
    RegistrationTab
  },
  data() {
    return {
      activeTab: 'login',
      dialogDisplay: false,
      isTabsHidden: false,
      isChiccoTimeRegistration: false,
      currentTab: this.activeTab,
      modalHasBonus: false,
      currentPhone: '',
      currentName: '',
      tabs: [
        {
          type: 'login',
          name: 'Вход'
        },
        {
          type: 'registration',
          name: 'Регистрация'
        }
      ],
      isCheckout: false
    };
  },
  computed: {
    ...mapGetters({
      hasOAuthLinks: 'user/hasOAuthLinks'
    })
  },
  watch: {
    dialogDisplay(isOpen) {
      if (!isOpen) {
        this.$eventBus.$emit('auth_modal:closed');
      }
      this.$eventBus.$emit('general:body:hold-scroll', isOpen);
    }
  },
  mounted() {
    this.$eventBus.$on('auth_modal:tabs::hide', this.hideTabs);
    this.$eventBus.$on('auth_modal:tabs::display', this.displayTabs);
    this.$eventBus.$on('modals:register:close', this.closeModal);
    this.$eventBus.$on('modals:register:open', this.openModal);
    this.$eventBus.$on('auth_forms:login_tab:success_notice:bonus', this.setModalHasBonus);
    this.$eventBus.$on('modals:auth_registration:display', this.displayAuthRegistrationModal);
    this.checkForHash();
  },
  beforeDestroy() {
    this.$eventBus.$off('auth_modal:tabs::hide', this.hideTabs);
    this.$eventBus.$off('auth_modal:tabs::display', this.displayTabs);
    this.$eventBus.$off('modals:register:open', this.openModal);
    this.$eventBus.$off('modals:register:close', this.closeModal);
    this.$eventBus.$off('auth_forms:login_tab:success_notice:bonus', this.setModalHasBonus);
    this.$eventBus.$off('modals:auth_registration:display', this.displayAuthRegistrationModal);
    this.$eventBus.$emit('general:body:hold-scroll', false);
  },
  methods: {
    ...mapActions({
      getOAuthLinks: 'user/getOAuthLinks'
    }),
    checkForHash() {
      switch (this.$route.hash) {
        case '#login':
          this.openModal(this.tabs[0].type);
          this.clearHash();
          break;
        case '#registration':
          this.openModal(this.tabs[1].type);
          this.clearHash();
          break;
        default:
          return;
      }
    },
    clearHash() {
      this.$router.replace({ hash: '' });
    },
    displayAuthRegistrationModal() {
      this.openModal('registration');
      setTimeout(() => this.$eventBus.$emit('auth_forms:oauth_phone_form:display'));
    },
    tabChangeHandler(index) {
      this.currentTab = index;
      this.$eventBus.$emit('auth_forms:validation:reset');
      this.$eventBus.$emit('auth_forms:phone_form:display');
    },
    hideTabs() {
      this.isTabsHidden = true;
    },
    displayTabs() {
      this.isTabsHidden = false;
    },
    openModal(tab, phone, name, isCheckout) {
      if (!this.hasOAuthLinks) {
        this.getOAuthLinks();
      }
      this.isChiccoTimeRegistration = false;

      if (tab === 'chicco-time-registration') {
        this.isChiccoTimeRegistration = true;
        this.activeTab = 'registration';
      } else {
        this.activeTab = tab || 'login';
      }

      this.isTabsHidden = false;
      this.dialogDisplay = true;
      this.currentTab = this.activeTab;
      this.currentPhone = phone || '';
      this.currentName = name || '';
      this.isCheckout = isCheckout || false;
    },
    closeModal() {
      this.dialogDisplay = false;
    },
    setModalHasBonus(has = false) {
      this.modalHasBonus = has;
    },
    setSelected(tab) {
      this.activeTab = tab;
      this.tabChangeHandler(tab);
    }
  }
};
</script>

<style lang="scss" src="~/assets/scss/auth-modal/_auth-modal.scss" />
