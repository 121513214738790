<template>
  <section id="registration-confirm-form">
    <div class="popup-window-title">
      <h6 class="popup-window-title__head">
        {{ $t('Здравствуйте') }} {{ $store.state.user.data.firstName || '' }} <br /><br />
        {{ $t('Установите ваш пароль') }}
      </h6>
    </div>
    <UcForm ref="form" :handler="changePassword">
      <uc-input
        v-model="password"
        v-focus
        rules="required|min:6"
        type="text"
        :label="$t('Пароль')"
        name="password"
        :title="$t('Введите пароль')"
        :with-star="false"
      />
      <div class="form-refresh m-xxxxlg-bottom">
        <span class="form-refresh__btn" @click="setPasswordLater()">{{ $t('Указать пароль позже') }}</span>
      </div>

      <uc-loader-button type="submit" class="uc-button-black" :loading="loading" :disabled="loading">{{
        $t('Сменить')
      }}</uc-loader-button>
    </UcForm>
  </section>
</template>

<script>
import AuthErrorsMixin from 'u-auth/lib/components/mixin/AuthErrors.mixin.js';
import focus from '~/src/directives/focus.js';
import { RouterMixin } from 'u-mixins';
import { userStore } from 'u-store';
const { mutationTypes: userMutation } = userStore;

export default {
  directives: { focus },
  mixins: [AuthErrorsMixin, RouterMixin],
  data: () => {
    return {
      password: '',
      loading: false
    };
  },
  mounted() {
    this.$eventBus.$emit('auth_modal:tabs::hide');
  },
  methods: {
    setPasswordLater() {
      this.$eventBus.$emit('auth_forms:register_welcome:display');
      this.$eventBus.$emit('auth_forms:login_tab:success_notice:display');
    },
    changePassword() {
      this.loading = true;
      this.$store
        .dispatch('user/changePassword', this.password)
        .then(() => {
          if (this.$store.state.user.isFromThanksPage) {
            this.$store.commit(`user/${userMutation.IS_FROM_THANKS_PAGE}`, false);
            this.moveToByName('profile-page', null, '#bonusprogram');
            return;
          }
          this.$eventBus.$emit('auth_forms:register_welcome:display');
          this.$eventBus.$emit('auth_forms:login_tab:success_notice:display');
        })
        .catch((error) => {
          this.handleErrors(error);
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
