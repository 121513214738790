<template>
  <section v-if="isDisplayOAuthBlock()" class="oauth-section">
    <a :href="oAuthLinks.facebook" class="popup-window-social-link">
      <div class="popup-window-social-link__icn">
        <img src="https://static.ma.com.ua/assets/svg/ma_fb.svg" alt="facebook" />
      </div>
      <span class="popup-window-social-link__text popup-window-social-link__text_fb">Facebook</span>
    </a>
    <a :href="oAuthLinks.google" class="popup-window-social-link">
      <div class="popup-window-social-link__icn">
        <img src="https://static.ma.com.ua/assets/svg/ma_google.svg" alt="google" />
      </div>
      <span class="popup-window-social-link__text popup-window-social-link__text_g">Google</span>
    </a>
  </section>
</template>

<script>
export default {
  computed: {
    oAuthLinks() {
      return this.$store.state.user.oAuthLinks.authentication;
    }
  },
  methods: {
    isDisplayOAuthBlock() {
      try {
        if (!this.oAuthLinks) {
          return false;
        }

        const isFacebook = Object.prototype.hasOwnProperty.call(this.oAuthLinks, 'facebook');
        const isGoogle = Object.prototype.hasOwnProperty.call(this.oAuthLinks, 'google');

        return isFacebook && isGoogle;
      } catch (error) {
        console.error('isDisplayOAuthBlock Error:', error);
      }
    }
  }
};
</script>
