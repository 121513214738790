<template>
  <section id="oauth-phone-form" class="m-t-8">
    <div class="popup-window-title m-b-35">
      <h6 class="popup-window-title__head">{{ $t('Регистрация') }}</h6>
    </div>
    <UcForm ref="form" :handler="registerNewUser">
      <uc-phone-input v-model="canonicalPhone" />
      <div class="auth-phone-text m-t-20 m-b-35">{{ $t('Мы отправим на этот номер код подтверждения') }}</div>
      <uc-loader-button type="submit" class="uc-button-black" :loading="loading" :disabled="loading">{{
        $t('Продолжить')
      }}</uc-loader-button>
    </UcForm>
  </section>
</template>

<script>
import AuthErrorsMixin from 'u-auth/lib/components/mixin/AuthErrors.mixin.js';
import { userStore } from 'u-store';
const { mutationTypes: userMutation } = userStore;

export default {
  mixins: [AuthErrorsMixin],
  data: () => {
    return {
      canonicalPhone: '',
      firstName: '',
      email: '',
      lastName: '',
      oauthId: '',
      provider: '',
      useRegisterOauth: false,
      loading: false
    };
  },
  mounted() {
    this.$eventBus.$emit('auth_modal:tabs::hide');
    const { email, name, oauthId, provider } = this.$store.state.user.oAuthFetchedData;
    if (oauthId && provider && name) {
      this.firstName = name;
      this.email = email;
      this.provider = provider;
      this.oauthId = oauthId;
      this.$store.commit(`user/${userMutation.SET_OAUTH_FETCHED_DATA}`, {});
      this.useRegisterOauth = true;
    }
  },
  methods: {
    registerNewUser() {
      this.loading = true;
      this.$store
        .dispatch('user/registerByPhone', {
          firstName: this.firstName,
          phone: this.canonicalPhone,
          email: this.email,
          oauthId: this.oauthId,
          provider: this.provider,
          useRegisterOauth: true
        })
        .then(() => {
          this.$store.commit(`user/${userMutation.SET_REGISTER_CANONICAL_PHONE}`, this.canonicalPhone);
          this.$eventBus.$emit('auth_forms:confirm_form:display');
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.code === 1105) {
            this.$notify({
              group: 'default',
              type: 'error',
              title: this.$t('Ошибка авторизации'),
              text: this.$t(
                'Мы нашли пользователя с таким телефоном среди наших клиентов. Это Вы? Тогда войдите на сайт с помощью номера телефона и пароля и привяжите социальную сеть в личном кабинете.'
              )
            });
          }
          this.handleErrors(error);
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
