<template>
  <section id="registration-form">
    <div class="popup-window-title">
      <h6 class="popup-window-title__head">
        <label>{{ $t('Регистрация') }}</label>
      </h6>
    </div>
    <UcForm ref="form" :handler="registerNewUser">
      <uc-phone-input v-model="phone" :with-star="false" />
      <uc-input
        id="login-first-name"
        v-model.trim="firstName"
        rules="required|alpha-hyphen"
        name="firstName"
        type="text"
        class="contacts-input"
        :label="$t('Имя')"
        :with-star="false"
        mode="eager"
      />
      <div
        v-if="isChiccoTimeRegistration"
        class="uc-input-field uc-input-has-value personal-information_line__value due-date"
      >
        <label class="uc-input-label">{{ $t('Предварительная дата родов') }}</label>
        <BirthDate v-model="birthDate" />
      </div>
      <div v-if="isAnonUser" class="popup-window-confirm">
        {{ $t('Подтвердите свой номер телефона, чтобы войти в личный кабинет') }}
      </div>
      <div v-if="isAnonUser" class="popup-window-soc">
        {{ $t('Или продолжить, используя социальные сети') }}:
        <oauth-block />
      </div>
      <div v-if="!isAnonUser && !isChiccoTimeRegistration">
        <div class="popup-window-desc__soc">{{ $t('Или продолжить, используя социальные сети') }}:</div>
        <oauth-block />
      </div>
      <uc-loader-button type="submit" class="uc-button-black" :loading="loading" :disabled="loading">{{
        $t('Далее')
      }}</uc-loader-button>
      <uc-button class="uc-button-white-black auth-cancel" @click="$eventBus.$emit('modals:register:close')">
        {{ $t('Отмена') }}
      </uc-button>
    </UcForm>
  </section>
</template>

<script>
import { userStore } from 'u-store';

import AuthErrorsMixin from 'u-auth/lib/components/mixin/AuthErrors.mixin.js';
import BirthDate from 'u-components/components/lib/profile/BirthDate.vue';

import focus from '~/src/directives/focus.js';
import OauthBlock from '~/src/components/auth/form/OauthBlock.vue';
const { mutationTypes: userMutation } = userStore;

export default {
  components: {
    OauthBlock,
    BirthDate
  },
  directives: { focus },
  mixins: [AuthErrorsMixin],
  props: {
    currentPhone: {
      type: String,
      default: ''
    },
    currentName: {
      type: String,
      default: ''
    },
    isChiccoTimeRegistration: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      phone: '',
      firstName: '',
      birthDate: null,
      loading: false
    };
  },
  computed: {
    isAnonUser() {
      return this.currentPhone.length;
    }
  },
  mounted() {
    if (this.currentPhone.length) {
      this.phone = this.currentPhone;
      this.firstName = this.currentName || '';
    } else {
      this.phone = this.$store.state.user.registerCanonicalPhone;
    }
  },
  methods: {
    registerNewUser() {
      this.loading = true;
      this.$store
        .dispatch('user/registerByPhone', {
          firstName: this.firstName,
          phone: this.phone,
          pregnancyDueDate: this.birthDate,
          useRegisterOauth: false
        })
        .then(() => {
          this.$store.commit(`user/${userMutation.SET_REGISTER_CANONICAL_PHONE}`, this.phone);
          this.$eventBus.$emit('auth_forms:confirm_form:display');
          if (this.isAnonUser) {
            this.$store.commit(`user/${userMutation.IS_FROM_THANKS_PAGE}`, true);
          }
        })
        .catch((error) => {
          this.handleErrors(error);
          if (error.response.status === 429) {
            this.$refs.form.setErrors({
              phone: [this.$t('Превышен лимит попыток регистрации.')]
            });
            return;
          }
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
