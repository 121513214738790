<template>
  <section>
    <auth-loading-spinner v-if="loading || !bonusRequestFinished" />
    <section v-else>
      <template v-if="isBonusProgramMember && profile">
        <div class="auth-bonus-status">
          <div class="auth-bonus-status_text">{{ $t('Здравствуйте') }}, {{ $store.state.user.data.firstName }}!</div>
          <div class="auth-bonus-status_text">
            {{ $t('Ваш Статус') }}: <span>{{ profile.levelNameRu }}</span>
          </div>

          <div v-if="profile.rate" class="auth-bonus-status_percent">
            {{ $t('Получайте') }} {{ profile.rate }}%
            {{ $t('кешбэка за каждую покупку') }}
          </div>
        </div>
        <div class="auth-bonus-status-frame">
          <div class="auth-bonus-status-frame_title">{{ $t('На Вашем счету') }}:</div>
          <div class="auth-bonus-status-frame_text">
            {{ profile.activeAmount | currency }}
            <img
              class="auth-bonus-status-frame__img"
              :src="`https://static.ma.com.ua/assets/svg/ma-cashback-pink_${$i18n.locale}.svg`"
              alt="ma-cashback"
            />
          </div>
          <div class="auth-bonus-status_percent">
            {{ $t('Оплачивайте МА Кешбэком до 50% от стоимости продуктов, обозначенных диаМАнтом') }}
          </div>
        </div>
      </template>

      <div v-else class="auth-bonus-status">
        <div class="auth-bonus-status_text">{{ $t('Здравствуйте') }}, {{ $store.state.user.data.firstName }}!</div>
        <div class="auth-nobonus_text">{{ $t('Станьте участником программы') }}</div>
        <div class="auth-nobonus_logo">
          <img :src="`https://static.ma.com.ua/assets/svg/ma-cashback-pink_${$i18n.locale}.svg`" alt="ma-cashback" />
        </div>
        <div v-if="profile && !isBonusProgramMember" class="auth-nobonus_description">
          {{ $t('и получите') }}
          <span class="auth-nobonus_description__bonus">50 грн</span>
          {{ $t('кешбэка') }}.
        </div>
        <div v-if="!isBonusProgramMember" class="auth-nobonus_description">
          {{ $t('Просто заполните профиль в личном кабинете') }}!
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import AuthLoadingSpinner from '~/src/components/page-blocks/AuthLoadingSpinner';
import BonusProgramMemberMixin from '~/src/mixins/BonusProgramMember';

export default {
  name: 'BonusAuthGreetings',
  components: {
    AuthLoadingSpinner
  },
  mixins: [BonusProgramMemberMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bonusRequestFinished: false
    };
  },
  computed: {
    loading() {
      this.$emit('input', this.$store.state.bonus.loading);
      return this.$store.state.bonus.loading;
    },
    profile() {
      return this.$store.state.bonus.profile;
    }
  },
  watch: {
    isBonusProgramMember: {
      async handler() {
        try {
          await this.$bonus.fetchBonusProfile();
        } catch {}
        this.bonusRequestFinished = true;
      },
      immediate: true
    }
  }
};
</script>
